<template>
    <div class="wrapper_main footer-fixed" :class="{ processing: loading }" style="height: 100vh; padding-bottom: 20px;">
        <main class="content" v-if="!loading" style="max-height: 100dvh;">
            <div class="headline flex items-center">
                <router-link to="/actions-list" class="btn btn-w-shadow btn-sm btn-back">
                    <svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path xmlns="http://www.w3.org/2000/svg" d="M14 8L10 12L14 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </router-link>
                <h1 class="headline__title" style="margin: 0;margin-left: 15px;">View {{ $translate('action') }}</h1>
                <!-- <p class="headline__text">{{ $translate('set-up-action-and-attahch-fields-or-animals') }}</p> -->
            </div>
            <div class="tabs-wrapper">
                <ul class="tabs">
                    <li class="tabs__item" v-for="mainFilterItem in mainTabs" :key="mainFilterItem.type">
                        <a data-tab="type" class="nav-tab" @click="switchTab(mainFilterItem.type)" :class="isActiveTab(mainFilterItem.type)">
                            {{ mainFilterItem.title }}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="tab-content">
                <div id="type" class="tab-content__item" :class="isActiveTab('type')">
                    <div class="addition">
                        <div class="addition__group">
                            <div class="headline headline--sm">
                                <h2 class="headline__title">{{ $translate('category') }}</h2>
                                <!-- <p class="headline__text">{{ $translate('select-on-of-two-categories') }}</p> -->
                            </div>
                            <span>{{ category }}</span>
                        </div>
                        <div class="form-group form-group--wrapper mt-15">
                            <div class="addition__group">
                                <div class="form-group__row">
                                    <div class="form-group__col-5 mb-15">
                                        <div class="el-form" :key="updateKeyNumber">
                                            <label class="el-form__title" style="color: rgb(157, 176, 183)">{{ $translate('type-of-activity') }}</label>
                                            <span>{{ basicInfo?.type_of_acivity?.split('_').join(' ') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
                <template v-if="category == 'fields'">
                    <div v-for="tab in ['activity-info', 'product-info', 'notes-info']" :key="tab" :id="tab" class="tab-content__item" :class="isActiveTab(tab)">
                        <div class="addition" v-if="tab === activeTab">
                            <div class="form-group form-group--wrapper">
                                <div class="addition__group">
                                    <div class="form-group__row">
                                        <template v-for="(basicInfoItem, bInfoIndex) in actionBasicInfoConfig.fields[tab]">
                                            <div
                                                :class="[basicInfoItem.checkboxes || basicInfoItem.textarea ? 'form-group__col-10' : 'form-group__col-5 mb-15', { 'mb-15': basicInfoItem.textarea }]"
                                                :key="bInfoIndex + '-' + updateKeyNumber"
                                                v-if="!basicInfoItem.time && 
                                                    (basicInfoItem.id === 'product_category'?basicInfo['type_of_product']:true)&&
                                                    (basicInfoItem.id === 'type_of_adversity'?basicInfo['adversity']:true)
                                                "
                                            >
                                                <div class="el-form" >
                                                    <div class="headline headline--sm" v-if="basicInfoItem.name && basicInfoItem.headlineTitle">
                                                        <h2 class="headline__title">{{ basicInfoItem.name }}</h2>
                                                    </div>

                                                    <label class="el-form__title" v-if="basicInfoItem.name && !basicInfoItem.headlineTitle" style="color: #9db0b7">{{ basicInfoItem.name }}</label>

                                                    <div class="form-group__row form-group__row--sm" v-if="basicInfoItem.datepicker || basicInfoItem.timepicker">
                                                        <div class="form-group__col-sm" :class="[basicInfoItem.timepicker ? 'form-group__col-5' : 'form-group__col-10']" v-if="basicInfoItem.datepicker">
                                                            {{ basicInfo[basicInfoItem.id] }}
                                                        </div>
                                                        <div class="form-group__col-5 form-group__col-sm" v-if="basicInfoItem.timepicker">
                                                            <div class="time-piker w-max-none">
                                                                <!-- {{ basicInfo[basicInfoItem.id.replace('date', 'time')] }} -->
                                                                {{ basicInfo[basicInfoItem.id] }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span v-else-if="basicInfoItem.id === 'name_of_the_product'">
                                                        {{ basicInfo[basicInfoItem.id] }}
                                                    </span>
                                                    <span v-else-if="basicInfoItem.id === 'tool_equipment'">
                                                        {{ toolEquipmentItems.find(toolEquipment => toolEquipment.id === basicInfo[basicInfoItem.id]) }}
                                                    </span>
                                                    <span v-else-if="basicInfoItem.id === 'type_of_product'">
                                                        {{ basicInfo[basicInfoItem.id] }}
                                                    </span>
                                                    <span v-else-if="basicInfoItem.id === 'product_category'">
                                                        {{ basicInfo[basicInfoItem.id] }}
                                                    </span>
                                                    <span v-else-if="basicInfoItem.id === 'type_of_adversity'">
                                                        {{ basicInfo[basicInfoItem.id] }}
                                                    </span>
                                                    <span v-else-if="basicInfoItem.options">
                                                        {{ basicInfo[basicInfoItem.id] }}
                                                    </span>
                                                    <div class="form-group__row" v-else-if="basicInfoItem.checkboxes || basicInfoItem.radio" style="pointer-events: none;">
                                                        <div
                                                            class="form-group__col-5"
                                                            :class="{ 'mb-15': basicInfoItem.checkboxes }"
                                                            v-for="(checkboxItem, checkboxIndex) in basicInfoItem.checkboxes ? basicInfoItem.checkboxes : basicInfoItem.radio"
                                                            :key="checkboxItem + '-' + checkboxIndex"
                                                        >
                                                            <div class="el-form">
                                                                <label class="module__check" v-if="basicInfoItem.checkboxes">
                                                                    <input type="checkbox" name="category" :value="checkboxItem" v-model="basicInfo[basicInfoItem.id]" />
                                                                    <span class="check"></span>
                                                                    <span class="text">{{ checkboxItem }}</span>
                                                                </label>

                                                                <div class="base-radio" v-else>
                                                                    <input
                                                                        class="base-radio__input"
                                                                        :value="checkboxItem"
                                                                        type="radio"
                                                                        :id="basicInfoItem.id + '-' + checkboxIndex"
                                                                        :name="basicInfoItem.id"
                                                                        v-model="basicInfo[basicInfoItem.id]"
                                                                    />
                                                                    <label class="base-radio__label" :for="basicInfoItem.id + '-' + checkboxIndex">{{ checkboxItem }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="textarea textarea--lg" v-else-if="basicInfoItem.textarea && basicInfo[basicInfoItem.id]">
                                                        {{ basicInfo[basicInfoItem.id] || '-' }}
                                                    </div>

                                                    <label
                                                        class="module__check" v-else-if="basicInfoItem.checkbox"
                                                        style="display: flex;align-items: center;min-height: 40px;pointer-events: none;"
                                                    >
                                                        <input type="checkbox" name="category" v-model="basicInfo[basicInfoItem.id]" />
                                                        <span class="check"></span>
                                                        <span class="text">{{ basicInfoItem.name }}</span>
                                                    </label>

                                                    <span v-else>{{ basicInfo[basicInfoItem.id] || '-' }}</span>
                                                    <input 
                                                        type="text" style="margin-top:15px" class="input" placeholder="Type of adversity" 
                                                        v-model="temp_type_of_adversity" v-if="basicInfoItem.id === 'type_of_adversity' && basicInfo[basicInfoItem.id] === 'Other'"
                                                    />
                                                </div>
                                            </div>

                                            <hr class="gray_hr" :key="bInfoIndex + '-hr'" v-if="basicInfoItem.hr" style="width: calc(100% - 20px); margin-left: 10px; margin-top: 5px" />
                                        </template>
                                        
                                        <div class="form-group__col-10 el-form" v-if="activeTab === 'activity-info'">
                                            <label class="el-form__title" style="display: block; color: rgb(157, 176, 183);">{{ $translate('media') }}</label>
                                            <div class="gallery mb-15" v-if="media.length" style="margin-top: 0; padding-top: 0;">
                                                <div class="gallery__item relative" v-for="(m, index) in media" :key="'media-'+index">
                                                    <img :src="m" style="object-fit: cover; min-width: 100%; width: initial; min-height: 100%;">
                                                </div>
                                            </div>
                                            <div v-else>Empty</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="category == 'herd'">
                    <div id="info" class="tab-content__item" :class="isActiveTab('info')">
                        <div class="addition">
                            <div class="form-group form-group--wrapper">
                                <div class="addition__group">
                                    <div class="form-group__row">
                                        <template v-for="(basicInfoItem, bInfoIndex) in actionBasicInfoConfig.herd">
                                            <div
                                                :class="[basicInfoItem.checkboxes || basicInfoItem.textarea ? 'form-group__col-10' : 'form-group__col-5 mb-15', { 'mb-15': basicInfoItem.textarea }]"
                                                :key="bInfoIndex + '-' + updateKeyNumber"
                                                v-if="!basicInfoItem.time"
                                            >
                                                <div class="el-form">
                                                    <div class="headline headline--sm" v-if="basicInfoItem.name && basicInfoItem.headlineTitle">
                                                        <h2 class="headline__title">{{ basicInfoItem.name }}</h2>
                                                    </div>

                                                    <label class="el-form__title" v-if="basicInfoItem.name && !basicInfoItem.headlineTitle" style="color: #9db0b7">{{ basicInfoItem.name }}</label>

                                                    <div class="form-group__row form-group__row--sm" v-if="basicInfoItem.datepicker || basicInfoItem.timepicker">
                                                        <div class="form-group__col-sm" :class="[basicInfoItem.timepicker ? 'form-group__col-5' : 'form-group__col-10']">
                                                            <DatePicker v-model="basicInfo[basicInfoItem.id]" />
                                                        </div>
                                                        <div class="form-group__col-5 form-group__col-sm" v-if="basicInfoItem.timepicker">
                                                            <div class="time-piker w-max-none">
                                                                <TimePicker v-model="basicInfo[basicInfoItem.id.replace('date', 'time')]" @change="updateKeyNumber++" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Dropdown
                                                        v-else-if="basicInfoItem.options"
                                                        :options="basicInfoItem.name === 'Name of the person' ? userNamesOptions : basicInfoItem.options"
                                                        :class="{ options_uncapitalize: basicInfoItem.name === 'Name of the person' }"
                                                        :current="basicInfo[basicInfoItem.id]"
                                                        :name="basicInfoItem.id"
                                                        @change="updateBasicInfo"
                                                    />
                                                    <div class="form-group__row" v-else-if="basicInfoItem.checkboxes || basicInfoItem.radio">
                                                        <div
                                                            class="form-group__col-5"
                                                            :class="{ 'mb-15': basicInfoItem.checkboxes }"
                                                            v-for="(checkboxItem, checkboxIndex) in basicInfoItem.checkboxes ? basicInfoItem.checkboxes : basicInfoItem.radio"
                                                            :key="checkboxItem + '-' + checkboxIndex"
                                                        >
                                                            <div class="el-form">
                                                                <label class="module__check" v-if="basicInfoItem.checkboxes">
                                                                    <input type="checkbox" name="category" :value="checkboxItem" v-model="basicInfo[basicInfoItem.id]" />
                                                                    <span class="check"></span>
                                                                    <span class="text">{{ checkboxItem }}</span>
                                                                </label>

                                                                <div class="base-radio" v-else>
                                                                    <input
                                                                        class="base-radio__input"
                                                                        :value="checkboxItem"
                                                                        type="radio"
                                                                        :id="basicInfoItem.id + '-' + checkboxIndex"
                                                                        :name="basicInfoItem.id"
                                                                        v-model="basicInfo[basicInfoItem.id]"
                                                                    />
                                                                    <label class="base-radio__label" :for="basicInfoItem.id + '-' + checkboxIndex">{{ checkboxItem }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <textarea
                                                        v-else-if="basicInfoItem.textarea"
                                                        class="textarea textarea--lg"
                                                        placeholder="Write notes here..."
                                                        v-model="basicInfo[basicInfoItem.id]"
                                                    ></textarea>

                                                    <input type="text" class="input" v-model="basicInfo[basicInfoItem.id]" v-else />
                                                </div>
                                            </div>

                                            <hr class="gray_hr" :key="bInfoIndex + '-hr'" v-if="basicInfoItem.hr" style="width: calc(100% - 20px); margin-left: 10px; margin-top: 5px" />
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <div id="fields-herd" class="tab-content__item" :class="isActiveTab('fields-herd')">
                    <div class="addition" style="pointer-events: none;">
                        <div class="addition__header">
                            <div class="headline headline--sm">
                                <h2 class="headline__title">{{ $translate('related-fields-/-herd') }}</h2>
                                <p class="headline__text">{{ $translate('attach-fields-or-herd-to-your-action') }}</p>
                            </div>
                        </div>
                        <ul class="editable-list">
                            <li class="editable-list__item" v-for="(relatedPaddockItem, riIndex) in relatedPaddocks" :key="riIndex">
                                <div class="data-row">
                                    <ul class="data-row__list">
                                        <li class="data-row__item">{{ relatedPaddockItem.basic_info.name }}</li>
                                        <li class="data-row__item">123</li>
                                        <li class="data-row__item">Crop Name</li>
                                        <li class="data-row__item">Variety Name</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </main>

        <ChangePaddockBuildingPopup
            v-if="popupData.show"
            :title="popupData.title"
            :multiple="true"
            :paddocks="popupData.paddocks"
            :value="related"
            :showLandRegistry="popupData.showLandRegistry"
            @close="closePopup"
            @submit="submitPopup"
        />
    </div>
</template>

<script>
import Base from "@/components/base";
import Popups from "@/components/popups";
import actionBasicInfoConfig from "@/config/farm";
import ChangePaddockBuildingPopup from "@/components/popups/ChangePaddockBuildingPopup";
import { API, Storage } from 'aws-amplify';
import { createFarmAction, updateFarmAction } from "@/graphql/mutations";
import { listFarmActions, getUser, getCompany } from "@/graphql/queries";
import productsList from "@/assets/json/products_list.json"

export default {
    name: "ViewAction",
    components: {
        ...Base,
        ...Popups,
        ChangePaddockBuildingPopup,
    },
    data() {
        return {
            loading: true,
            
            step: 1,
            categories: [
                { title: "Herd", value: "herd" },
                { title: "Fields", value: "fields" },
            ],
            category: "fields",
            subcategories: {
                herd: ["Vaccine", "Treatment"],
                fields: ["Seed", "Treatment", "Fertilizer", "Weeding", "Harvesting", "Pesticide"],
            },
            actionBasicInfoConfig: actionBasicInfoConfig.actionBasicInfoConfig,
            typeOfActivity:actionBasicInfoConfig.typeOfActivity,
            phenologicalStadium:actionBasicInfoConfig.phenologicalStadium,
            typeOfProduct:actionBasicInfoConfig.typeOfProduct,
            typeOfAdversity:actionBasicInfoConfig.typeOfAdversity,
            basicInfo: {

            },
            updateKeyNumber: 0,
            popupData: {
                title: 'Related Fields / Herd',
                show: false,
                paddocks: [],
                showLandRegistry: false,
            },
            related: [],
            editedAction: false,
            users: [],
            temp_type_of_adversity:null,
            media: []
        };
    },
    watch: {
        category() {
            if(this.loading) return

            this.initBasicInfo();
        },

      
    },
    computed: {

        productsList(){
            let createdProducts=(this.$store.state.products||[]).map(p=>({name:p.title,id:p.id}))
            return {
                // "Created Products":createdProducts,
                "All Products":productsList["Product list"]
            }
            // return productsList["Product list"]
        },
        mainTabs() {
            if (this.category == "fields") {
                return [
                    { type: "type", title: "Action Type" },
                    { type: "activity-info", title: "Activity Info" },
                    { type: "product-info", title: "Product Info" },
                    { type: "notes-info", title: "Notes Info" },
                    { type: "fields-herd", title: "Related Fields / Herd" },
                ];
            }
            if (this.category == "herd") {
                return [
                    { type: "type", title: "Action Type" },
                    { type: "info", title: "Basic Info" },
                    { type: "fields-herd", title: "Related Fields / Herd" },
                ];
            }
        },
        activeTab: {
            get() {
                return this.$route.query.tab || "type";
            },
            set(tab) {
                this.$router.push({ query: { tab } });
            },
        },
        isActiveTab() {
            return (tab) => (tab === this.activeTab ? "active" : "");
        },
        paddocks() {
            return this.$store.state.paddocks.filter((item) => item.type === "field" || item.type === "herd");
        },
        relatedPaddocks() {
            let returnRelatedPaddocks = [...this.paddocks.filter((item) => this.related.includes(item.id))];

            if(this.related.length){
                this.related.forEach(item => {
                    if(item.includes('-landregister-index-')){
                        if(!returnRelatedPaddocks.map(itemM => itemM.id).includes(item.split('-landregister-index-')[0])){
                            returnRelatedPaddocks.push(this.paddocks.find(itemF => itemF.id === item.split('-landregister-index-')[0]));
                        }
                    }
                })
            }

            return returnRelatedPaddocks;
        },
        userNamesOptions() {
            if (this.users.length) {
                return this.users.map((item) => `${item.fullname} (${item.email})`);
            } else {
                return ["Names list empty"];
            }
        },
        userNamesOptionsNotEmpty() {
            if (this.users.length) {
                return this.users.map((item) => `${item.fullname} (${item.email})`);
            } else {
                return [];
            }
        },
        toolEquipmentItems(){
            return this.$store.state.paddocks.filter((item) => item.type === "tool_equipment").map(paddockItem => ({ 'title': paddockItem.basic_info.name, 'id': paddockItem.id }));
        }
    },
    methods: {
        selectProduct(event){
            let product = event.target.value;
            let findedProduct = this.productsList["All Products"].find(item => item.id === product.id);

            if(findedProduct){
                this.basicInfo.active_substance = findedProduct.active_substances;
                this.basicInfo.content_for_100g = findedProduct.content_for_100g;
            }
        },
        switchTab(tab, replaceTab = true) {
            if (replaceTab) this.activeTab = tab;
            this.step = this.mainTabs.map((item) => item.type).findIndex((item) => item === tab) + 1;
        },
        updateBasicInfo(ev) {
            const target = ev.target;
            const value = target.value;
            const name = target.name;
            if(name=='adversity'&&this.basicInfo.adversity!==value){
                this.basicInfo.type_of_adversity=null
            }
            if(name=='type_of_product'&&this.basicInfo.type_of_product!==value){
                this.basicInfo.product_category=null
            }
            this.basicInfo[name] = value;
            this.updateKeyNumber++;
        },
        timePickerValueChange(value, fieldId) {
            if (this.basicInfo[fieldId].includes(":")) {
                this.basicInfo[fieldId] = this.basicInfo[fieldId].substring(0, this.basicInfo[fieldId].length - 6);
            }

            this.basicInfo[fieldId] += ` ${value}`;
        },
        getFromDateTime(dateString, type) {
            if (!dateString.length) return "";

            if (type === "time" && dateString.length > 10) {
                return dateString.substring(dateString.length - 6, dateString.length);
            }
        },
        changeSubcategory(subcategoryId) {
            this.subcategory = subcategoryId;
        },
        getFieldsConfig(category){
            let config=[]
            return config
        },
        
        initBasicInfo() {
            if(this.loading) return
            // this.basicInfo = {};
            let fieldsConfig = [...(this.actionBasicInfoConfig['herd'])];
            Object.values(this.actionBasicInfoConfig['fields']).forEach(tab=>{
                fieldsConfig=[...fieldsConfig,...tab]
            })
            for (let index = 0; index < fieldsConfig.length; index++) {
                this.$set(this.basicInfo,fieldsConfig[index].id,null)
                if (fieldsConfig[index].checkboxes) {
                    this.$set(this.basicInfo,fieldsConfig[index].id,[])
                }
            }
        },
        nextStep() {
            if (this.step < this.mainTabs.length) {
                this.step++;
                this.switchTab(this.mainTabs.map((item) => item.type)[this.step - 1]);
            }
        },
        addRelatedOpen(type) {
            this.popupData = {
                title: `Add Related ${type === 'field' ? 'fields' : type }`,
                show: true,
                paddocks: [...this.$store.state.paddocks.filter((item) => item.type === type)],
                showLandRegistry: type === 'field' ? true : false
            };
        },
        closePopup() {
            this.popupData = {
                show: false,
                paddocks: [],
            };
        },
        submitPopup(submitedPaddocks) {
            this.related = submitedPaddocks;
            this.closePopup();
        },
        relatedItemRemove(id) {
            this.related.splice(
                this.related.findIndex((findIitem) => findIitem === id),
                1
            );
        },
        async initFarm() {
            if (this.$store.state.companyData && this.$store.state.companyData.id) {
                let farm = await this.$store.dispatch("GET_FARM");
                if (!farm) {
                    await API.graphql({
                        query: createFarm,
                        variables: {
                            input: {
                                companyID: this.$store.state.companyData.id,
                            },
                        },
                    });

                    await this.$store.dispatch("GET_FARM");
                }
                await this.$store.dispatch("GET_PADDOCKS");
                await this.$store.dispatch("GET_DEVICES");
            } else {
                setTimeout(() => {
                    this.initFarm();
                }, 1000);
            }
        },
        async getUsers() {
            let company = await API.graphql({
                query: getCompany,
                variables: {
                    id: this.$store.state.companyData.id,
                },
            }).then((res) => res.data.getCompany);

            if(company.team && company.team.length){
                let requests = company.team.map(async (userID) => {
                    return await API.graphql({
                        query: getUser,
                        variables: {
                            id: userID,
                        },
                    }).then((res) => res.data.getUser);
                });
                await Promise.all(requests).then((res) => (this.users = res));
            }
        },
    },
    async created() {
        await this.initFarm();
        this.initBasicInfo();
        await this.getUsers();

        if (this.$route.name === "ViewAction") {
            this.basicInfo = {};

            this.switchTab(this.$route.query.tab, false);

            let editActionData = await API.graphql({
                query: listFarmActions,
                variables: {
                    id: this.$route.params.id,
                },
            }).then((res) => res.data.listFarmActions.items);

            editActionData = editActionData.filter((item) => item.id === this.$route.params.id)[0];

            if (editActionData) {
                console.log('editActionData:', editActionData);
                this.category = editActionData.category;
                this.related = editActionData.related;
                let fieldsConfig = [...(this.actionBasicInfoConfig['herd'])];

                Object.values(this.actionBasicInfoConfig['fields']).forEach(tab=>{
                    fieldsConfig=[...fieldsConfig,...tab]
                })
                for (let index = 0; index < fieldsConfig.length; index++) {
                    // this.basicInfo[fieldsConfig[index].id] = editActionData[fieldsConfig[index].id];
                    this.$set(this.basicInfo,fieldsConfig[index].id,editActionData[fieldsConfig[index].id])
                }
                ['type_of_acivity','phenological_stadium',].forEach((field)=>{
                    this.$set(this.basicInfo,field,editActionData[field])
                })
                if(editActionData.type_of_adversity&&editActionData.type_of_adversity.match('Other_')){
                    this.basicInfo.type_of_adversity=editActionData.type_of_adversity.split("_")[0]
                    this.temp_type_of_adversity=editActionData.type_of_adversity.split("_")[1]
                }

                if(editActionData?.media){
                    editActionData?.media.forEach(async(mediaKey) => {
                        let url = await Storage.get(mediaKey);
                        this.media.push(url);
                    });

                }
            
                this.editedAction = editActionData.id;
            }
        }
        if(!this.isActiveTab('type')){
            this.switchTab("type");
        }
        setTimeout(()=>{
            this.loading = false;
        },100)
    },
};
</script>


<style scoped>
.el-form textarea.textarea--lg {
    height: 100px;
}
</style>